import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { FontStyles } from '@constants/fontStyles';
import { BlueCheck, CardModalsDesktop, CardModalsMobile } from '@constants/images';

import useMediaQuery from '@hooks/useMediaQuery';

import Modal from '@components/Modal/Modal';
import ModalHeadline from '@components/Modal/ModalHeadline';
import ModalSubheadline from '@components/Modal/ModalSubheadline';

import LargeButton from '@elements/LargeButton/LargeButton';

const CardSetup = () => {
  const theme = useTheme();

  const isTablet = useMediaQuery(`(${theme.breakpoints.tablet})`);
  const [success, setSuccess] = useState(false);
  const [value, setValue] = useState('');
  const [valid, setValid] = useState(true);

  const setCardOrdered = useBoundStore((state) => state.setCardOrdered);
  const closeModal = useBoundStore((state) => state.closeModal);

  useEffect(() => {
    setValid(value.length <= 300);
  }, [setValid, value]);

  return (
    <CardSetupModal>
      {!success && isTablet && <ModalHeadline>Pre Order</ModalHeadline>}
      <ModalSubheadline>
        {!success && isTablet
          ? 'What specific services or transactions do you have in mind for your debit card usage?'
          : ''}
      </ModalSubheadline>
      <Content>
        {success ? (
          <>
            <BlueCheck></BlueCheck>
            <SuccessTitle>Success</SuccessTitle>
            <SucessSubtext>
              Your ticket has been successfully pre-ordered. You will be informed about the delivery
              by notification and e-mail.
            </SucessSubtext>
            <OrderNowButton
              className="orderNow"
              onClick={() => closeModal()}
              text="Done"
              variant="blueWhite"
            />
          </>
        ) : (
          <>
            <Title>Pre Order</Title>
            <Subtext>
              What specific services or transactions do you have in mind for your debit card usage?
            </Subtext>
            <Subtitle>Submit description below</Subtitle>
            <FieldWrapper>
              <Field
                value={value}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Type description here"
              />
              {!valid && (
                <Error>
                  <span>Yo dude, make it shorter</span>&nbsp;
                  <div>{value.length}/300</div>
                </Error>
              )}
            </FieldWrapper>
            <OrderNowButton
              className="orderNow"
              onClick={() => {
                setSuccess(true);
                setCardOrdered(true);
              }}
              text="Order Now"
              variant="blueWhite"
            />
          </>
        )}
      </Content>
    </CardSetupModal>
  );
};
export default CardSetup;

const CardSetupModal = styled(Modal)`
  position: relative;
  background:
    image-set(url(${CardModalsMobile[0]}) 1x, url(${CardModalsMobile[1]}) 2x) no-repeat bottom
      center / 100% auto,
    white;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    background:
      image-set(url(${CardModalsDesktop[0]}) 1x, url(${CardModalsDesktop[1]}) 2x) no-repeat bottom
        center / 100% auto,
      white;
    min-height: calc(59px + 76px + 307px + 50px); // elements sizes + padding top
    height: 760px;
    max-height: calc(100dvh - 140px);
  }
  padding-top: 50px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 40px;
  ${FontStyles.h3};
  color: black;
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const SuccessTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  ${FontStyles.h3}
  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.h2}
  }
`;

const Subtext = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  ${FontStyles.h6};
  margin-bottom: 30px;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

const SucessSubtext = styled.div`
  ${FontStyles.h6};
  display: flex;
  align-items: center;
  text-align: center;
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  @media (${(props) => props.theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
    color: #212121;
  }
`;

const OrderNowButton = styled(LargeButton)`
  &.orderNow {
    min-width: 230px;
    margin-top: 48px;
  }
`;
const Error = styled.div`
  ${FontStyles.bodySmallGilroy};
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.negative.contrastRed};
`;

const Subtitle = styled.div`
  ${FontStyles.h5};
  color: #3f3e62;
`;

const Field = styled.textarea`
  min-width: 303px;
  min-height: 190px;
  border: 1px solid ${(props) => props.theme.palette.greys.middleGrey};
  color: ${(props) => props.theme.palette.greys.darkestGrey};
  border-radius: 15px;
  ${FontStyles.bodyMiddleGilroy};
  padding: 16px 19px;
  box-sizing: border-box;
  resize: none;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    min-width: 530px;
    min-height: 159px;
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.palette.primary.blue};
  }
`;

const FieldWrapper = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;
`;
