import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar } from '@shared/css';

import { IconClose } from '@constants/icons';

import { useLockBodyScroll } from '@hooks/useLockBodyScroll';

interface Props {
  children: ReactNode;
  backdropBackground?: ModalBackdropBackgroundType;
  className?: string;
  showCloseIcon?: boolean;
  dark?: boolean;
}

const Modal = ({
  showCloseIcon = true,
  children,
  backdropBackground = 'dark',
  className,
  dark,
}: Props) => {
  const closeModal = useBoundStore((state) => state.closeModal);

  useLockBodyScroll(true);

  const onContentClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Overlay
      $backdropBackground={backdropBackground}
      onClick={closeModal}
    >
      <OverlayClickWrapper onClick={(e) => onContentClick(e)}>
        <ModalContainer className={className}>
          {showCloseIcon && (
            <CloseIcon
              $dark={!!dark}
              onClick={() => {
                closeModal();
              }}
            >
              <IconClose />
            </CloseIcon>
          )}
          <ModalContentContainer>{children}</ModalContentContainer>
        </ModalContainer>
      </OverlayClickWrapper>
    </Overlay>
  );
};

const Overlay = styled.div<{
  $backdropBackground: ModalBackdropBackgroundType;
}>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  -webkit-backdrop-filter: ${(props) =>
    props.$backdropBackground === 'light' ? css`blur(10.5px)` : css`blur(9px)`};
  background: ${(props) =>
    props.$backdropBackground === 'light'
      ? css`linear-gradient(249.15deg, rgba(255, 255, 255, 0.92) 2.79%, rgba(255, 255, 255, 0.92) 97.66%)`
      : props.theme.palette.gradients.greySilverGradient.alpha};
  z-index: 1000;
  justify-content: center;
  animation: ${(props) => props.theme.animations.fadeIn} 0.8s;
`;

const OverlayClickWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    width: initial;
    height: initial;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.primary.white};
  max-width: 320px;
  border-radius: 20px;
  @media (${({ theme }) => theme.breakpoints.mobile}) {
    max-width: 396px;
  }
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  overflow: hidden auto;
  ${HideScrollbar};

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: unset;
  }
`;
const CloseIcon = styled.div<{
  $dark: boolean;
}>`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: ${(props) => (props.$dark ? '#FFF' : '#000')};

  & > svg {
    height: 14px;
    width: 14px;
  }
`;

export default Modal;
