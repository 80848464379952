import { CanceledError } from 'axios';

import { weavrAuthorizedAxiosClient } from '@clients/axios-client';

const getTransactions = async (transactionRequest: TransactionRequest) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/transactions', {
      params: transactionRequest,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};
const getContactTransactions = async (contactId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get(
      `/transactions/contact/${contactId}`
    );
    return axiosResponse.data.transactions;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const getSummary = async (start: Date, end: Date, managedAccountId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get<TransactionSummaryResponse>(
      '/transactions/summary',
      {
        params: { start, end, managedAccountId: managedAccountId },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const getOverviewData = async (
  filterType: OverviewFilterType,
  limit: number,
  managedAccountId: string,
  start?: Date
) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get<OverviewResponse>(
      '/transactions/overview',
      {
        params: { start, filterType, limit, managedAccountId },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const getMostCommonHashtag = async (start: Date, managedAccountId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/transactions/tag', {
      params: { start, managedAccountId },
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const getRecentHashtag = async (managedAccountId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/transactions/recentTag', {
      params: { managedAccountId },
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const getAnalyticsChart = async (managedAccountId: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/transactions/chart', {
      params: { managedAccountId },
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      console.error(error);
    }
  }
};

const newTransfer = async (transaction: WeavrTransaction) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/transactions/new/transfer',
      transaction
    );
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const cancelTransfer = async (transaction: CancelTransfer) => {
  if (transaction.cancellations[0].id === 'cancel') return;
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/transactions/bulk/cancel',
      transaction
    );
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

const getAllOutgoingWireTransfers = async (params: WeavrOutgoingWireTransferParams) => {
  try {
    const config = {
      params,
    };
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/outgoing/wire/all', config);
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

const getOutgoingWireTransfer = async (id: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get(`/transactions/single/${id}`);
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

const getScheduledTransactions = async (
  signal: AbortSignal,
  managedAccountId: string,
  searchQuery: string
) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.get('/transactions/scheduled', {
      params: { managedAccountId, searchQuery },
      signal,
    });
    return axiosResponse.data;
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      throw error;
    }
  }
};

const exportCsv = async (transactionRequest: TransactionRequest, ids?: ExportTransaction[]) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post('/transactions/export/csv', ids, {
      params: transactionRequest,
    });
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

const updateTransactionComment = async (id: string, comment: string) => {
  try {
    const axiosResponse = await weavrAuthorizedAxiosClient.post(
      '/transactions/comment',
      {},
      {
        params: { id, comment },
      }
    );
    return axiosResponse.data;
  } catch (error) {
    console.error(error);
  }
};

export const TransactionsService = {
  getTransactions,
  getOverviewData,
  getSummary,
  getMostCommonHashtag,
  getAnalyticsChart,
  newTransfer,
  cancelTransfer,
  getAllOutgoingWireTransfers,
  getOutgoingWireTransfer,
  getContactTransactions,
  getScheduledTransactions,
  getRecentHashtag,
  exportCsv,
  updateTransactionComment,
};
