import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { useOverviewData } from '@api/Overview/overviewApi';

import { useBoundStore } from '@stores/BoundStore';

import { HideScrollbar, Scrollbar } from '@shared/css';
import { TransactionCommons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';

import useMediaQuery from '@hooks/useMediaQuery';
import useOnboardingSteps from '@hooks/useOnboardingSteps';

import CashFlowSummary from '@components/CashFlowSummary/CashFlowSummary';
import NoMatchesFound from '@components/NoMatchesFound/NoMatchesFound';
import TransactionDetailsPendingModal from '@components/PendingDepositModal/TransactionDetailsPendingModal';
import TabNavigation from '@components/TabNavigation/TabNavigation';
import VerificationBox from '@components/VerificationBox/VerificationBox';
import AnalyticsLinkTransactionEntry from '@components/analytics/AnalyticsLinkTransactionEntry/AnalyticsLinkTransactionEntry';
import TransactionDetails from '@components/transaction/TransactionDetails';
import TransactionEntry from '@components/transaction/TransactionEntry/TransactionEntry';
import TransactionEntryDummy from '@components/transaction/TransactionEntry/TransactionEntryDummy';

import SelectBox from '@elements/SelectBox/SelectBox';

const defaultOverviewData = {
  transactions: [],
  sums: {
    positiveSum: 0,
    negativeSum: 0,
  },
};

const Transactions = () => {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(`(${breakpoints.tablet})`);
  const isLaptop = useMediaQuery(`(${breakpoints.laptop})`);
  const { isKYBApprovedStatus, userHasManagedAccounts } = useOnboardingSteps();

  const setCollapsed = useBoundStore((state) => state.setCollapsed);
  const collapsed = useBoundStore((state) => state.collapsed);
  const openModal = useBoundStore((state) => state.openModal);

  const dropDownOptions = [
    { text: t('all'), value: 'all' },
    { text: t('this_month'), value: 'this_month' },
    { text: t('last_three_months'), value: 'last_three_months' },
    { text: t('last_six_months'), value: 'last_six_months' },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownValue, setDropdownValue] = useState<string>(dropDownOptions[0].value);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const scrollableRef = useRef<HTMLDivElement>(null);

  const tabs = [{ label: t('all') }, { label: t('outgoing') }, { label: t('incoming') }];

  const getDate = (option: string) => {
    const date = new Date();

    switch (option) {
      case 'last_three_months':
        date.setMonth(date.getMonth() - 3);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      case 'last_six_months':
        date.setMonth(date.getMonth() - 6);
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      case 'this_month':
        return new Date(date.getFullYear(), date.getMonth(), 1);
      default:
        return;
    }
  };

  const memoizedDate = useMemo(() => getDate(dropdownValue), [dropdownValue]);

  const filterType = {
    0: 'all',
    1: 'outgoing',
    2: 'incoming',
  }[currentIndex > 2 ? 0 : currentIndex] as OverviewFilterType;

  const { data: managedAccountData } = useGetAllManagedAccounts(isKYBApprovedStatus);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';

  const { data: overviewData } = useOverviewData(
    filterType,
    isTablet ? 30 : 3,
    managedAccountId,
    memoizedDate
  );

  const { transactions, sums } = overviewData ?? defaultOverviewData;
  const { positiveSum, negativeSum } = sums;

  useEffect(() => {
    const scrollableTransactionContainer = scrollableRef.current;

    if (scrollableTransactionContainer && isLaptop) {
      const handleScroll = () => {
        setIsScrolledToTop(scrollableTransactionContainer.scrollTop === 0);
        setIsScrolledToBottom(
          scrollableTransactionContainer.scrollTop + scrollableTransactionContainer.clientHeight ===
            scrollableTransactionContainer.scrollHeight
        );
      };

      scrollableTransactionContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollableTransactionContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isLaptop]);

  useEffect(() => {
    if (isScrolledToTop) setCollapsed(false);
    if (isScrolledToBottom) setCollapsed(true);
  }, [isScrolledToBottom, isScrolledToTop, setCollapsed]);

  return (
    <Container
      $verified={isKYBApprovedStatus && userHasManagedAccounts}
      className={collapsed ? 'collapsed' : ''}
    >
      <FilterGroup>
        <TabNavigation
          currentTabIndex={currentIndex}
          setCurrentTabIndex={setCurrentIndex}
          tabs={tabs}
          uniqueName="transaction-overview"
        />
        <SelectBox
          value={dropdownValue}
          setValue={setDropdownValue}
          options={dropDownOptions}
        />
      </FilterGroup>
      <Divider />
      <ContentGroup>
        <HeadLineGroup>
          <HeadlinePhone>{t('transactions')}</HeadlinePhone>
          <HeadlineTablet>{t('spendings_this_month')}</HeadlineTablet>
        </HeadLineGroup>
        <TransactionGroup
          ref={scrollableRef}
          className={
            transactions.length < 1 && isKYBApprovedStatus && userHasManagedAccounts
              ? 'noMatches'
              : ''
          }
        >
          {isKYBApprovedStatus && userHasManagedAccounts ? (
            <>
              {transactions.length < 1 ? (
                <NoMatchesFound />
              ) : (
                transactions.map((transaction, index) => {
                  const IS_STATUS_PENDING = transaction.status.toUpperCase() === 'PENDING';
                  return (
                    <TransactionEntry
                      key={index}
                      name={transaction.name}
                      type={transaction.type}
                      direction={transaction.direction}
                      status={transaction.status}
                      icon={TransactionCommons.getTransactionIcon(transaction)}
                      amount={transaction.amount}
                      timestamp={transaction.dateAndTime}
                      currency={transaction.currency}
                      onClick={() => {
                        openModal(
                          IS_STATUS_PENDING ? (
                            <TransactionDetailsPendingModal transaction={transaction} />
                          ) : (
                            <TransactionDetails
                              transaction={transaction}
                              icon={TransactionCommons.getTransactionIcon(transaction)}
                            />
                          )
                        );
                      }}
                    />
                  );
                })
              )}
              {transactions.length >= 20 && <AnalyticsLinkTransactionEntry />}
            </>
          ) : (
            <>
              <TransactionEntryDummy />
              <VerificationBox />
            </>
          )}
        </TransactionGroup>
        <ContentRight>
          <CashFlowWrapper>
            <CashFlowSummary
              kind="incoming"
              balance={positiveSum}
              currency="EUR"
              selected={currentIndex === 2}
              onClick={() => {
                setCurrentIndex((prevState) => {
                  if (prevState === 2) return 0;
                  return 2;
                });
              }}
            />
            <CashFlowSummary
              kind="outgoing"
              balance={negativeSum * -1}
              currency="EUR"
              selected={currentIndex === 1}
              onClick={() => {
                setCurrentIndex((prevState) => {
                  if (prevState === 1) return 0;
                  return 1;
                });
              }}
            />
          </CashFlowWrapper>
          <AnalyticsButton to="analytics">{t('show_analytics')}</AnalyticsButton>
        </ContentRight>
      </ContentGroup>
      <LargeMobileButton to="/analytics">{t('view_more')}</LargeMobileButton>
    </Container>
  );
};

const Container = styled.div<{ $verified: VerifiedKYCType }>`
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.primary.white};
  height: ${({ $verified }) => ($verified ? '400px' : '100%')};
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  margin: 0 20px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: unset;
    min-height: ${({ $verified }) => ($verified ? '400px' : '600px')};
    height: 100%;
    flex: 1 0 calc(50% - 10px);

    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 399px)'
        : !$verified
          ? 'calc(100vh - 429px)'
          : 'calc(100vh - 399px)'};
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding: 30px 35px;
    border-radius: 20px;
    max-width: initial;
    height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 435px)'
        : !$verified
          ? 'calc(100vh - 478px)'
          : 'calc(100vh - 435px)'};
    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 435px)'
        : !$verified
          ? 'calc(100vh - 478px)'
          : 'calc(100vh - 435px)'};

    &.collapsed {
      height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 245px)'
          : !$verified
            ? 'calc(100vh - 288px)'
            : 'calc(100vh - 245px)'};
      max-height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 245px)'
          : !$verified
            ? 'calc(100vh - 288px)'
            : 'calc(100vh - 245px)'};
    }
  }

  @media (${({ theme }) => theme.breakpoints.smallDesktop}) {
    min-height: 400px;
    height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 445px)'
        : !$verified
          ? 'calc(100vh - 488px)'
          : 'calc(100vh - 445px)'};
    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 445px)'
        : !$verified
          ? 'calc(100vh - 488px)'
          : 'calc(100vh - 445px)'};
    margin: 0 0 40px 40px;

    &.collapsed {
      min-height: 590px;
      height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 255px)'
          : !$verified
            ? 'calc(100vh - 89px)'
            : 'calc(100vh - 255px)'};
      max-height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 255px)'
          : !$verified
            ? 'calc(100vh - 89px)'
            : 'calc(100vh - 255px)'};
    }
  }

  @media (${({ theme }) => theme.breakpoints.desktop}) {
    height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 455px)'
        : !$verified
          ? 'calc(100vh - 498px)'
          : 'calc(100vh - 455px)'};
    max-height: ${({ $verified }) =>
      $verified
        ? 'calc(100vh - 455px)'
        : !$verified
          ? 'calc(100vh - 498px)'
          : 'calc(100vh - 455px)'};
    margin: 0 0 40px 40px;

    &.collapsed {
      min-height: 590px;
      height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 265px)'
          : !$verified
            ? 'calc(100vh - 99px)'
            : 'calc(100vh - 265px)'};
      max-height: ${({ $verified }) =>
        $verified
          ? 'calc(100vh - 265px)'
          : !$verified
            ? 'calc(100vh - 99px)'
            : 'calc(100vh - 265px)'};
    }
  }
`;

const Divider = styled.div`
  display: none;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 10px;
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.palette.greys.lightLightGrey};
  }
`;

const FilterGroup = styled.div`
  display: none;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  height: 394px;
  box-sizing: border-box;
  overflow: hidden;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: 100%;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    flex-direction: row;
    padding-top: 30px;
  }
`;

const HeadLineGroup = styled.div`
  display: flex;
  box-sizing: border-box;

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const HeadlinePhone = styled.h5`
  ${FontStyles.h5};
  display: flex;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const HeadlineTablet = styled.h5`
  ${FontStyles.h5};
  display: none;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
  }
`;

const TransactionGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  order: 2;
  box-sizing: border-box;
  max-height: 185px;
  margin-bottom: 10px;
  position: relative;

  &.noMatches {
    justify-content: center;
    align-items: center;
    max-height: unset;
    flex: 1;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    overflow: hidden auto;
    padding-right: 10px;
    ${Scrollbar};
    max-height: initial;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    gap: 10px;
    order: unset;
    margin: unset;
    ${HideScrollbar};
    padding-right: 12.5px;
  }
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  order: 1;
  margin-top: 15px;
  box-sizing: border-box;
  flex-shrink: 0;

  & > :last-child {
    display: none;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: unset;
    margin-left: 12.5px;
    flex-direction: column;
    order: unset;
    justify-content: start;
    max-width: 232px;

    & > :last-child {
      display: flex;
    }
  }
`;

const CashFlowWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    gap: 6px;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    gap: 20px;
    flex-direction: column;
    padding-bottom: 20px;
  }
`;

const LargeMobileButton = styled(NavLink)`
  ${FontStyles.buttonGilroyType3};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  border: 1px solid ${({ theme }) => theme.palette.greys.middleGrey};
  padding: 15px 39px;
  flex: 1 0;
  border-radius: 75px;
  margin-top: auto;
  max-height: 40px;
  box-sizing: border-box;
  order: 2;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  @media (${({ theme }) => theme.breakpoints.laptop}) {
    display: none;
  }
`;

const AnalyticsButton = styled(NavLink)`
  display: flex;
  height: 40px;
  border-radius: 75px;
  text-decoration: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.palette.primary.black};
  color: ${({ theme }) => theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  transition: 300ms ease-in-out;
  flex-shrink: 0;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.white};
    background-color: ${({ theme }) => theme.palette.primary.black};
  }
`;

export default Transactions;
