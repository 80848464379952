import styled, { css, useTheme } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconShare } from '@constants/icons';

import IconButton from '@elements/IconButton/IconButton';
import PopOver from '@elements/PopOver/PopOver';

interface Props {
  IBAN: string;
  BIC: string;
  onClick: () => void;
  collapsed: boolean;
}

const removeSpaces = (text: string) => {
  return text.replace(/\s+/g, '');
};

const BankDetails = ({ IBAN, BIC, onClick, collapsed }: Props) => {
  const theme = useTheme();

  return (
    <Container $collapsed={collapsed}>
      <Headline $collapsed={collapsed}>
        Bank Details
        <ShareDetailsIconButton
          onClick={onClick}
          icon={<IconShare />}
        />
      </Headline>
      <InfoWrapper $collapsed={collapsed}>
        <Row>
          <Heading>IBAN:</Heading>
          <CustomPopOver
            hoverColor={theme.palette.primary.white}
            copyValue={removeSpaces(IBAN)}
          />
        </Row>
        <Iban>{IBAN}</Iban>
        <Row>
          <Heading>BIC:</Heading>
          <Bic>{BIC}</Bic>
          <CustomPopOver
            hoverColor={theme.palette.primary.white}
            copyValue={BIC}
          />
        </Row>
      </InfoWrapper>
    </Container>
  );
};

export default BankDetails;

const ShareDetailsIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.palette.greys.darkGrey};

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.white};
    background-color: ${(props) => props.theme.palette.primary.white};
  }
`;

const Container = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.$collapsed ? '189' : ' 265')}px;
  padding: ${(props) => (props.$collapsed ? '0 0 0 26.5' : ' 20')}px;
  background: ${(props) => props.theme.palette.gradients.primaryBlackGradient};
  border-radius: 20px;
  background-size: 180% auto;
  box-sizing: border-box;
  transition: box-shadow 300ms ease-in-out;

  &:hover {
    background-position: 90% 120%;

    ${(props) =>
      !props.$collapsed &&
      css`
        -webkit-box-shadow: 0 20px 24px 0 rgba(123, 123, 138, 0.52);
        box-shadow: 0 20px 24px 0 rgba(123, 123, 138, 0.52);
      `}
  }
`;

const InfoWrapper = styled.div<{ $collapsed: boolean }>`
  display: ${(props) => (props.$collapsed ? 'none' : 'initial')};
  margin-top: auto;
`;

const Row = styled.div`
  display: flex;
`;

const Headline = styled.div<{ $collapsed: boolean }>`
  display: flex;
  justify-content: space-between;
  ${FontStyles.buttonsNeuePlakType1};
  color: ${(props) => props.theme.palette.primary.white};
  align-items: ${(props) => (props.$collapsed ? 'center' : 'initial')};
`;

const Heading = styled.div`
  ${FontStyles.bodyMiddleGilroy};
  color: ${(props) => props.theme.palette.greys.darkGrey};
`;

const Iban = styled.div`
  ${FontStyles.buttonGilroyType3SmallIbanOnly};
  margin-top: 10px;
  margin-bottom: 18px;
  color: ${(props) => props.theme.palette.primary.white};
`;

const Bic = styled.div`
  ${FontStyles.buttonGilroyType3};
  color: ${(props) => props.theme.palette.primary.white};
  margin-left: 10px;
`;

const CustomPopOver = styled(PopOver)`
  margin-left: 10px;
`;
