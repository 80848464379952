import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import {
  useGetContactsByTransactionId,
  useUpdateTransactionComment,
} from '@api/SendMoney/sendMoneyApi';

import { useBoundStore } from '@stores/BoundStore';

import { Commons, TransactionCommons } from '@shared/functions';

import { EditNote } from '@constants/icons';

import useMediaQuery from '@hooks/useMediaQuery';

import CopyField from '@components/CopyField/CopyField';
import SupportingDocument from '@components/SupportingDocument/SupportingDocument';
import {
  Content,
  ContentScroll,
  ContentWrapper,
  EditWrap,
  Footer,
  FooterLower,
  FooterLowerButtonWrapper,
  HeadText,
  IconWithBackground,
  Label,
  NoteInput,
  SubText,
  TransactionDetailModal,
  Wrapper,
} from '@components/transaction/TransactionDetails/TransactionDetails.styles';
import TransactionDetailsOverview from '@components/transaction/TransactionDetailsOverview/TransactionDetailsOverview';

import LargeButton from '@elements/LargeButton/LargeButton';
import SavePopOver from '@elements/SavePopOver/SavePopOver';

interface Props {
  transaction: DbTransaction;
  icon: ReactElement;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${format(date, 'EEEE, d MMMM yyyy')} at ${format(date, 'kk:mm')}`;
};

const TransactionDetails = ({ transaction, icon }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const verifiedKYC = useBoundStore((state) => state.verifiedKYC);
  const closeModal = useBoundStore((state) => state.closeModal);
  const isTablet = useMediaQuery(`(${theme.breakpoints.tablet})`);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedContact = useBoundStore((state) => state.selectedContact);
  const setSelectedContact = useBoundStore((state) => state.setSelectedContact);
  const selectedTransaction = useBoundStore((state) => state.selectedTransaction);
  const setSelectedTransaction = useBoundStore((state) => state.setSelectedTransaction);
  const setRepeat = useBoundStore((state) => state.setRepeat);

  const [commentValue, setCommentValue] = useState<string>(transaction.comment ?? '');
  const [initialComment, setInitialComment] = useState<string>(transaction.comment ?? '');

  const { mutate: updateComment } = useUpdateTransactionComment();

  const navigate = useNavigate();

  const { data: managedAccountData } = useGetAllManagedAccounts(verifiedKYC);

  const managedAccountId = managedAccountData?.accounts ? managedAccountData.accounts[0].id : '';
  const { data: contactData } = useGetContactsByTransactionId(
    managedAccountId,
    selectedTransaction?.transactions.id || ''
  );

  const handleSaveClick = async () => {
    if (initialComment !== commentValue) {
      updateComment(
        { id: transaction.id, comment: commentValue },
        {
          onSuccess: async () => {
            setInitialComment(commentValue);
            await queryClient.invalidateQueries({
              queryKey: ['contactTransactions'],
              exact: false,
              refetchType: 'active',
            });
            await queryClient.invalidateQueries({
              queryKey: ['overviewData'],
              exact: false,
              refetchType: 'active',
            });
            await queryClient.invalidateQueries({
              queryKey: ['transactionRequest'],
              exact: false,
              refetchType: 'active',
            });
          },
        }
      );
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentValue(event.target.value);
  };

  const repeatTransaction = (transactionInfo: DbTransaction) => {
    if (location.pathname === '/send-money') {
      closeModal();
    }

    setRepeat(true);

    setSelectedTransaction({
      transactions: {
        id: transactionInfo.id,
        name: transactionInfo.name,
        amount: transactionInfo.amount,
        currency: transactionInfo.currency,
        tag: transactionInfo.tag,
        dateAndTime: transactionInfo.dateAndTime,
        scheduledAt: transactionInfo.scheduledAt,
        status: transactionInfo.status,
        userId: transactionInfo.userId,
        externalId: transactionInfo.externalId,
        recipientAddress: transactionInfo.recipientAddress,
        accountNumber: transactionInfo.accountNumber,
        iban: transactionInfo.iban,
        bicSwift: transactionInfo.bicSwift,
        routingNumber: transactionInfo.routingNumber,
        bankName: transactionInfo.bankName,
        bankAddress: transactionInfo.bankAddress,
        bankCountry: transactionInfo.bankCountry,
        vendor: transactionInfo.vendor,
        reason: transactionInfo.reason,
        fee: transactionInfo.fee,
        reference: transactionInfo.reference,
        additionalBeneficiaryDetails: transactionInfo.additionalBeneficiaryDetails,
        type: transactionInfo.type,
      },
      contacts: null,
    });
  };

  useEffect(() => {
    if (contactData && selectedTransaction) {
      setSelectedContact(contactData.contacts[0]);
    }
  }, [contactData, selectedTransaction, setSelectedContact]);

  useEffect(() => {
    if (selectedTransaction && selectedContact) {
      closeModal();
      navigate('/send-money');
    }
  }, [closeModal, navigate, selectedContact, selectedTransaction]);

  const orderedKeys: (keyof DbTransaction)[] = [
    'name',
    'amount',
    'fee',
    'currency',
    'dateAndTime',
    'scheduledAt',
    'direction',
    'externalId',
    'status',
    'iban',
    'bicSwift',
    'bankName',
    'bankAddress',
    'bankCountry',
    'reference',
    'supportingDocument',
  ];

  return (
    <TransactionDetailModal>
      <Wrapper>
        <IconWithBackground kind={transaction.direction}>{icon}</IconWithBackground>
        <HeadText>{t('transaction_details')}</HeadText>
        <SubText>
          {t('transaction_details_subheading')}
          {transaction && ' ' + transaction.tag}
        </SubText>
        <TransactionDetailsOverview
          icon={TransactionCommons.getTransactionIcon(transaction)}
          direction={transaction.direction}
          type={transaction.type}
          name={transaction.name}
          status={transaction.status}
        />
        <ContentWrapper>
          <ContentScroll>
            <Content>
              {orderedKeys.map((key) => {
                if (transaction[key] === null && key === 'scheduledAt') {
                  return (
                    <CopyField
                      oneLine={true}
                      key={key}
                      label="Scheduled Execution Date"
                      value={formatDate(transaction['dateAndTime'].toString())}
                    />
                  );
                } else if (
                  transaction[key] !== undefined &&
                  transaction[key] !== null &&
                  transaction[key] !== '' &&
                  transaction[key] !== 'managedAccountId'
                ) {
                  if (key.toString() === 'supporting_document') {
                    return (
                      <SupportingDocument
                        key={key}
                        file={transaction[key] as File}
                      />
                    );
                  } else if (key === 'dateAndTime') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Authorization Date"
                        value={formatDate(transaction[key])}
                      />
                    );
                  } else if (key === 'direction') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Type of Transction"
                        value={transaction[key] === 'in' ? 'Incoming' : 'Outgoing'}
                      />
                    );
                  } else if (key === 'scheduledAt') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Scheduled Execution Date"
                        value={formatDate(transaction[key].toString())}
                      />
                    );
                  } else if (key === 'amount') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={key}
                        value={Commons.currencyFormatter(transaction[key]).toString()}
                      />
                    );
                  } else if (key === 'fee') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="fee"
                        value={Commons.currencyFormatter(transaction[key]).toString()}
                      />
                    );
                  } else if (key === 'externalId') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="transaction_id"
                        value={transaction[key]}
                      />
                    );
                  } else if (key === 'name') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={transaction.direction === 'in' ? 'sender' : 'beneficiary'}
                        value={transaction[key]}
                      />
                    );
                  } else if (key.toString() === 'additional_details') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={
                          transaction.direction === 'in'
                            ? 'additional_sender_details'
                            : 'additional_beneficiary_details'
                        }
                        value={transaction[key]?.toString() || ''}
                      />
                    );
                  } else if (key === 'status') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="transaction_status"
                        value={TransactionCommons.getStatus(transaction[key]) || transaction[key]}
                      />
                    );
                  } else if (
                    key === 'type' ||
                    key === 'id' ||
                    key === 'userId' ||
                    key === 'currency'
                  ) {
                    return;
                  } else {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={key}
                        value={transaction[key]?.toString() || ''}
                      />
                    );
                  }
                }
              })}
            </Content>
          </ContentScroll>
        </ContentWrapper>
        <Footer>
          <Label>Leave a note that may help you later </Label>
          <NoteInput
            value={commentValue}
            placeholder="Leave your note here"
            reference={inputRef}
            icon={
              !initialComment || initialComment !== commentValue ? (
                <SavePopOver
                  onClick={() => handleSaveClick()}
                  isSavable={initialComment !== commentValue}
                />
              ) : (
                <EditWrap>
                  <EditNote onClick={() => handleSaveClick()} />
                </EditWrap>
              )
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChange(event);
            }}
          />
          <FooterLower>
            <FooterLowerButtonWrapper>
              {transaction.direction === 'out' && (
                <LargeButton
                  onClick={() => repeatTransaction(transaction)}
                  text="Repeat"
                  variant="blueWhite"
                  style={{ padding: '15px 30px', minWidth: '70px' }}
                />
              )}
            </FooterLowerButtonWrapper>
          </FooterLower>
        </Footer>
      </Wrapper>
    </TransactionDetailModal>
  );
};

export default TransactionDetails;
