import { StateCreator } from 'zustand';

import { config } from '../config';

export interface WeavrUiComponentLibrarySlice {
  isWeavrUiComponentLibraryLoaded: boolean;
  loadWeavrUiComponentLibrary: () => void;
}

export const createWeavrUiComponentLibrarySlice: StateCreator<WeavrUiComponentLibrarySlice> = (
  set
) => ({
  isWeavrUiComponentLibraryLoaded: false,
  loadWeavrUiComponentLibrary: () => {
    const script = document.createElement('script');
    script.src = config.REACT_APP_WEAVR_UI_LIBRARY_URL;
    script.onload = () => {
      window.OpcUxSecureClient.init(config.REACT_APP_WEAVR_UI_KEY);
      set({ isWeavrUiComponentLibraryLoaded: true });
    };
    script.onerror = () => {
      console.error('Failed to load the script');
      // Optionally handle setting an error state if needed
    };
    //TODO this creates the log in console -> Getting frame by name a Window{window: Window, self: Window, document: document, name: '', location: Location,…}
    document.body.appendChild(script);
  },
});
