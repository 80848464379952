/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { usePopper } from 'react-popper';
import styled from 'styled-components';

import { FontStyles } from '@constants/fontStyles';
import { IconCopied, IconCopy } from '@constants/icons';

interface Props {
  copyValue: string;
  hoverColor?: string;
  className?: string;
}

const PopOver = ({ copyValue, hoverColor, className }: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
    placement: 'top',
  });

  const [opened, setOpened] = useState<boolean>(false);

  const handleClick = () => {
    setOpened(true);
    const timer = setTimeout(() => {
      setOpened(false);
    }, 1000);

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(copyValue).catch((err) => {
        console.error('Could not copy text: ', err);
      });
    } else {
      try {
        const textarea = document.createElement('textarea');
        textarea.value = copyValue;
        textarea.setAttribute('readonly', ''); // Prevents keyboard from showing on mobile
        textarea.style.contain = 'strict';
        textarea.style.position = 'absolute';
        textarea.style.zIndex = '-1';
        textarea.style.left = '-9999px';

        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        textarea.style.top = `${yPosition}px`;

        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999);

        document.execCommand('copy');
        document.body.removeChild(textarea);
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  return (
    <>
      <Button
        $opened={opened}
        $hoverColor={hoverColor}
        onClick={() => handleClick()}
        type="button"
        ref={setReferenceElement}
        className={className}
      >
        {opened ? <IconCopied /> : <IconCopy />}
      </Button>
      {opened && (
        <>
          <Content
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            Copied
            <Arrow
              ref={setArrowElement}
              style={styles.arrow}
            />
          </Content>
        </>
      )}
    </>
  );
};
export default PopOver;

const Button = styled.button<{ $opened: boolean; $hoverColor?: string }>`
  display: flex;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) =>
    props.$opened ? props.theme.palette.primary.blue : props.theme.palette.greys.darkestGrey};
  transition: 300ms ease-in-out;

  &:hover {
    color: ${({ $opened, theme, $hoverColor = theme.palette.primary.black }) =>
      $opened ? theme.palette.primary.blue : $hoverColor};
  }
`;

const Content = styled.div`
  display: flex;
  padding: 10px 15px;
  background-color: white;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  ${FontStyles.h6};
  color: ${(props) => props.theme.palette.primary.blue};
  height: 40px;
  box-sizing: border-box;
`;

const Arrow = styled.div`
  display: flex;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  margin-top: 45px;
`;
