import { create } from 'zustand';

import { AnalyticsSlice, createAnalyticsSlice } from '@stores/AnalyticsSlice';
import { ContactListSlice, createContactListSlice } from '@stores/ContactListSlice';
import {
  CorporateRegistrationDataSlice,
  createCorporateRegistrationDataSlice,
} from '@stores/CorporateRegistrationDataSlice';
import { GlobalStorageSlice, createGlobalStorageSlice } from '@stores/GlobalStorageSlice';
import { NotificationSlice, createNotificationSlice } from '@stores/NotificationSlice';
import { PasswordResetSlice, createPasswordResetSlice } from '@stores/PasswordResetSlice';
import { PinTimerSlice, createPinTimerSlice } from '@stores/PinTimerSlice';
import { TransactionSlice, createTransactionSlice } from '@stores/TransactionSlice';
import { UserSlice, createUserSlice } from '@stores/UserSlice';
import {
  WeavrUiComponentLibrarySlice,
  createWeavrUiComponentLibrarySlice,
} from '@stores/WeavrUiComponentLibrarySlice';

export const useBoundStore = create<
  ContactListSlice &
    GlobalStorageSlice &
    TransactionSlice &
    NotificationSlice &
    PinTimerSlice &
    CorporateRegistrationDataSlice &
    WeavrUiComponentLibrarySlice &
    UserSlice &
    AnalyticsSlice &
    PasswordResetSlice
>()((...a) => ({
  ...createContactListSlice(...a),
  ...createGlobalStorageSlice(...a),
  ...createTransactionSlice(...a),
  ...createNotificationSlice(...a),
  ...createPinTimerSlice(...a),
  ...createCorporateRegistrationDataSlice(...a),
  ...createWeavrUiComponentLibrarySlice(...a),
  ...createUserSlice(...a),
  ...createAnalyticsSlice(...a),
  ...createPasswordResetSlice(...a),
}));
