const config = (() => {
  const runtimeConfig = (window as unknown as { RUNTIME_CONFIG: Record<string, string> })
    .RUNTIME_CONFIG;

  const configObject = {
    REACT_APP_TITLE: process.env.REACT_APP_TITLE || runtimeConfig.REACT_APP_TITLE,
    REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL || runtimeConfig.REACT_APP_BASE_URL,
    REACT_APP_API_URL: process.env.REACT_APP_API_URL || runtimeConfig.REACT_APP_API_URL,
    REACT_APP_WEBSOCKET_URL:
      process.env.REACT_APP_WEBSOCKET_URL || runtimeConfig.REACT_APP_WEBSOCKET_URL,
    REACT_APP_WEAVR_UI_KEY:
      process.env.REACT_APP_WEAVR_UI_KEY || runtimeConfig.REACT_APP_WEAVR_UI_KEY,
    REACT_APP_WEAVR_UI_LIBRARY_URL:
      process.env.REACT_APP_WEAVR_UI_LIBRARY_URL || runtimeConfig.REACT_APP_WEAVR_UI_LIBRARY_URL,
    REACT_APP_MICROSOFT_CLARITY_ID:
      process.env.REACT_APP_MICROSOFT_CLARITY_ID || runtimeConfig.REACT_APP_MICROSOFT_CLARITY_ID,
    REACT_APP_TERMS_LINK: process.env.REACT_APP_TERMS_LINK || runtimeConfig.REACT_APP_TERMS_LINK,
    REACT_APP_WEAVR_DEFAULT_MANAGED_ACCOUNT_FEE:
      process.env.REACT_APP_WEAVR_DEFAULT_MANAGED_ACCOUNT_FEE ||
      runtimeConfig.REACT_APP_WEAVR_DEFAULT_MANAGED_ACCOUNT_FEE,
    REACT_APP_WEAVR_DEFAULT_OWT_FEE:
      process.env.REACT_APP_WEAVR_DEFAULT_OWT_FEE || runtimeConfig.REACT_APP_WEAVR_DEFAULT_OWT_FEE,
    REACT_APP_TURNSTILE_SITE_KEY:
      process.env.REACT_APP_TURNSTILE_SITE_KEY || runtimeConfig.REACT_APP_TURNSTILE_SITE_KEY,
  };

  return configObject;
})();

export { config };
