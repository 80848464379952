// import { useGetAllManagedAccounts } from '@api/ManagedAccounts/managedAccountsApi';
import { electronicFormatIBAN, validateIBAN } from 'ibantools';

import { Commons, getFees } from '@shared/functions';

import { convertStringToNumber } from '@components/transaction/halper';

const regExName = /^[a-zA-Z0-9/\-?:().,’+\s#=!"%&*<>;{@\r\n]*$/;
const getAmountWithFees = (amount: string) => {
  const fees = getFees('SEPA');
  const amountNumber = convertStringToNumber(amount);

  return amountNumber + fees;
};
const getBalance = (balance: number) => {
  return balance > 0 ? Commons.formatWeavrAmountToPeanuds(balance) : 0;
};
export const amountValidate = (value: string, balance: number): string => {
  if (!value) {
    return 'Please enter the transfer amount.';
  }

  const parsedValue = convertStringToNumber(value);
  if (parsedValue < 0) {
    return 'Please enter a valid amount.';
  }

  if (parsedValue < 0.1) {
    return 'Amount must be at least 0,10.';
  }

  if (getBalance(balance) < getAmountWithFees(value)) {
    return 'The amount exceeds the available balance.';
  }

  return ''; // No error, validation passed
};

export const recipientValidate = (value: string) => {
  if (!value) {
    // eslint-disable-next-line quotes
    return "Please enter the recipient's full name.";
  }
  if (value.length >= 100) {
    // eslint-disable-next-line quotes
    return "The recipient's name exceeds the allowed length";
  }
  if (regExName.test(value)) {
    return '';
  } else {
    // eslint-disable-next-line quotes
    return "Please enter a valid recipient's full name.";
  }
};
export const ibanValidate = (value: string) => {
  if (value) {
    const iban = electronicFormatIBAN(value);
    if (iban) {
      const { valid, errorCodes } = validateIBAN(iban);

      if (valid) return '';
      if (errorCodes.length > 0) {
        return 'Invalid IBAN format.';
      }
    }
  }
  return 'Please enter a valid IBAN.';
};
export const descriptionValidate = (value: string) => {
  if (value) {
    if (value.length > 35) {
      return 'The description exceeds the allowed length.';
    } else {
      return '';
    }
  } else {
    // eslint-disable-next-line quotes
    return 'Please enter a description.';
  }
};
