import { StateCreator } from 'zustand';

interface ModalInstance {
  modal: JSX.Element;
}

export interface GlobalStorageSlice {
  verifiedKYC: VerifiedKYCType;
  setVerifiedKYC: (value: boolean) => void;
  cardCreated: boolean;
  setCardCreated: (value: boolean) => void;
  scrolled: boolean;
  setScrolled: (value: boolean) => void;
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  cardSetupVisible: boolean;
  setCardSetupVisible: (value: boolean) => void;
  cardOrdered: boolean;
  setCardOrdered: (value: boolean) => void;
  fullAccessUser: boolean;
  setFullAccessUser: (value: boolean) => void;
  invitedUser: boolean;
  setInvitedUser: (value: boolean) => void;
  isLoggedIn: boolean;
  /**
   * Set to null to log user out
   * @param {string | null} token
   */
  setIsLoggedIn: (token: string | null) => void;
  modals: ModalInstance[];
  openModal: (modal: JSX.Element, replace?: boolean) => void;
  closeModal: () => void;
  inactivityTime: number;
  setInactivityTime: (value: number | ((value: number) => number)) => void;
  supportVisible: boolean;
  setSupportVisible: (value: boolean) => void;
  settingsIndex: number;
  setSettingsIndex: (value: number | ((value: number) => number)) => void;
}

export const createGlobalStorageSlice: StateCreator<GlobalStorageSlice> = (set) => ({
  scrolled: false,
  setScrolled: (value) => set({ scrolled: value }),
  collapsed: false,
  setCollapsed: (value) => set({ collapsed: value }),
  modals: [],
  openModal: (modal, replace = false) =>
    set((state) => {
      const lastModal = state.modals[state.modals.length - 1];

      // Check if the new modal is the same as the last modal
      if (lastModal && lastModal.modal.type.name === modal.type.name) {
        return state; // No changes to the state if the modal is the same
      }

      const newModalsState =
        replace && state.modals.length > 0
          ? [...state.modals.slice(0, -1), { modal }]
          : [...state.modals, { modal }];

      return { modals: newModalsState };
    }),
  closeModal: () => set((state) => ({ modals: state.modals.slice(0, -1) })),
  isLoggedIn: localStorage.getItem('@@peanuds_auth@@') !== null,
  setIsLoggedIn: (value) => {
    if (!value) {
      localStorage.removeItem('@@peanuds_auth@@');
      localStorage.removeItem('lastActiveTime');
    } else {
      localStorage.setItem('@@peanuds_auth@@', value);
    }
    set({ isLoggedIn: localStorage.getItem('@@peanuds_auth@@') !== null });
  },
  verifiedKYC: null,
  setVerifiedKYC: (value) => set({ verifiedKYC: value }),
  supportVisible: false,
  setSupportVisible: (value) => set({ supportVisible: value }),
  settingsIndex: 0,
  setSettingsIndex: (value) =>
    set((state) => ({
      settingsIndex: typeof value === 'function' ? value(state.settingsIndex) : value,
    })),

  //TODO THESE STATES ARE FOR TESTING PURPOSES ONLY
  cardCreated: false,
  setCardCreated: (value) => set({ cardCreated: value }),
  cardSetupVisible: false,
  setCardSetupVisible: (value) => set({ cardSetupVisible: value }),
  cardOrdered: false,
  setCardOrdered: (value) => set({ cardOrdered: value }),
  fullAccessUser: true,
  setFullAccessUser: (value) => set({ fullAccessUser: value }),
  invitedUser: false,
  setInvitedUser: (value) => set({ invitedUser: value }),

  inactivityTime: 300,
  setInactivityTime: (value) =>
    set((state) => ({
      inactivityTime: typeof value === 'function' ? value(state.inactivityTime) : value,
    })),
});
