import { StateCreator } from 'zustand';

export interface PasswordResetSlice {
  lostPasswordMail: string;
  setLostPasswordMail: (value: string) => void;
  lostPasswordCaptcha: CloudflareCaptcha;
  setLostPasswordCaptcha: (value: CloudflareCaptcha) => void;
}

export const createPasswordResetSlice: StateCreator<PasswordResetSlice> = (set) => ({
  lostPasswordMail: '',
  setLostPasswordMail: (value) => set({ lostPasswordMail: value }),
  lostPasswordCaptcha: {
    captchaToken: null,
    idempotencyRef: null,
    isValid: false,
  },
  setLostPasswordCaptcha: (value) => set({ lostPasswordCaptcha: value }),
});
